'use strict';

/* Creating Scroll-based Animations using jQuery and CSS3, see http://www.sitepoint.com/scroll-based-animations-jquery-css3/ */

(function () {
    
    //Cache reference to window and animation items
    var $window = $(window);
    var window_top_position, window_bottom_position;

    function getElementDisplayDelay(element) {
        var delayPrefix = 'delay-';
        var cssClasses = element.attr('class');
        var delay = undefined;
        
        if (cssClasses) {
            cssClasses.split(' ').forEach(function (val) {
                val = val.trim();
                
                // if starts with 'delay-'
                if (val.indexOf(delayPrefix) === 0) {
                    delay = +val.substr(delayPrefix.length);
                }
            });
        }
        return delay;
    }

    function animateElement(element, animationClass, showElement) {
        if (showElement) {
            element.show();
        }
        element.removeClass('animation-element animation-left-element animation-right-element animation-up-element animation-down-element');
        element.addClass('animated ' + animationClass);
    }

    function animateAllVisibleElemets(animationFunction) {
        return function () {
            var $element = $(this);
            var element_height = $element.outerHeight();
            var element_top_position = $element.offset().top;
            var element_bottom_position = (element_top_position + element_height);

            //check to see if this current container is within viewport
            if ((element_bottom_position >= window_top_position) &&
                (element_top_position <= window_bottom_position)) {
                var delay = getElementDisplayDelay($element);
                
                animationFunction($element, delay);
            }
        }
    }

    function performDefaultAnimation(animationClass) {
        return animateAllVisibleElemets(function ($element, delay) {
            if (delay) {
                $element.hide();
                setTimeout(animateElement, delay, $element, animationClass, true);
            } else {
                animateElement($element, animationClass);
            }
        });
    }
    
    function animateElements() {
        $.each($('.animation-element, .animation-left-element'), performDefaultAnimation('fadeInLeftBig'));
        $.each($('.animation-right-element'), performDefaultAnimation('fadeInRightBig'));
        $.each($('.animation-up-element'), performDefaultAnimation('fadeInUpBig'));
        $.each($('.animation-down-element'), performDefaultAnimation('fadeInDownBig'));
    }

    function fillOutProgressBars() {
        $.each($('.progress.animate > .progress-bar'), animateAllVisibleElemets(function ($element, delay) {
            var value = $element.attr('aria-valuenow') + '%';
            
            if (delay) {
                setTimeout(function() { $element.css('width', value); }, delay);
            } else {
                $element.css('width', value);
            }
        }));
    }

    function check_if_in_view() {
        var window_height = $window.height();

        window_top_position = $window.scrollTop();
        window_bottom_position = (window_top_position + window_height);
        
        animateElements();
        fillOutProgressBars();
    }

    $window.on('scroll resize', check_if_in_view);
    $(document).ready(check_if_in_view);
} ());
